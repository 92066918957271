import React from "react"

import static_page from '../images/static_page.svg'
import wordpress from '../images/wordpress.svg'
import javascript_frameworks from '../images/javascript_frameworks.svg'
import maintenance from '../images/maintenance.svg'
import hosting from '../images/hosting.svg'

import Layout from "../components/layout"

import SEO from "../components/seo"

const ServicesPage = (props) => (
  <Layout>
    <SEO title="Services" />
    <div className="container">
    <h1 className="PageH1">Services</h1>
    <div className="twoColHolder">
      <div className="twoCol">
        <h3 className="serviceTitle">Plain website with HTML, CSS and Javascript</h3>
        <p>This is our most basic web service which is suited best for small websites which are not updated that often.</p>
      </div>
      <div className="twoCol">
        <img className="services_component" src={static_page} alt="Static setup"></img>
      </div>
    </div>

    <div className="twoColHolder">
      <div className="twoCol">
        <img className="services_component" src={wordpress} alt="Wordpress setup"></img>
      </div>
      <div className="twoCol">
        <h3 className="serviceTitle">Wordpress site</h3>
        <p>Wordpress is a great choice for websites that are updated on a regular basis. Wordpress is the most used content management system on the web and provide a fantastic user experience.</p>
      </div>
    </div>

    <div className="twoColHolder">
      <div className="twoCol">
        <h3 className="serviceTitle">Static webpage with Gatsby js and React</h3>
        <p>This is the best choice for websites that want the best performance and usability possible.</p>
      </div>
      <div className="twoCol">
        <img className="services_component" src={javascript_frameworks} alt="Javascript frameworks"></img>
      </div>
    </div>
    <h1>Other services</h1>
    <p>A website is more than just a design. With almost every website comes maintenance and somewhere to host it. We want to provide our customers a total solution were we manage everything web related.</p>
    <div className="twoColHolder">
      <div className="twoCol">
      <img className="services_component" src={maintenance} alt="Maintenance"></img>
      </div>
      <div className="twoCol">
        <h3 className="serviceTitle">Maintenance</h3>
        <p>Every project comes with a one month full support, in case anything goes wrong. However, for a small additional fee per month, we offer full support of your webpage for as long a you like, thus eliminating any trouble that you may have to deal with.</p>
      </div>
    </div>

    <div className="twoColHolder">
      <div className="twoCol">
        <h3 className="serviceTitle">Hosting</h3>
        <p>We also provide hosting for websites in case you do not like to do any maintenance on your web solution. If you would like to use your own hosting provider, we will make sure that your new website gets up and running on a host of your choice.</p>
      </div>
      <div className="twoCol">
        <img className="services_component" src={hosting} alt="Web hosting"></img>
      </div>
    </div>
    </div>
  </Layout>
)

export default ServicesPage

/*
<Img fluid={props.data.imageOne.childImageSharp.fluid} />
    <Img fluid={props.data.imageTwo.childImageSharp.fluid} />
    <Img fluid={props.data.imageThree.childImageSharp.fluid} />

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "gatsby-astronaut.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "gatsby-astronaut.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "gatsby-astronaut.png" }) {
      ...fluidImage
    }
  }
`

<p>We offer a wide variety of services within web development for both companies and individuals.... </p>
    <h2>Technologies we use</h2>
    <p>We always use the best techologies for your needs wether it is a dynamic website with Wordpress or a static website with Gatsby js and React.</p>
    <div className="services_list">
    <h3>Plain website with HTML, CSS and Javascript</h3>
    <img className="services_component" src={static_page} alt="Static setup"></img>
    <p className="services_component">This is our most basic web service which is suited best for small websites which are not updated that often.</p>
    </div>
    <div className="services_list">
    <h3>Wordpress site</h3>
    <img className="services_component" src={wordpress} alt="Wordpress setup"></img>
    <p className="services_component">Wordpress is a great choice for websites that are updated on a regular basis. Wordpress is the most used content management system on the web and provide a fantastic user experience.</p>
    </div>
    <div className="services_list">
    <h3>Static webpage with Gatsby js and React</h3>
    <img className="services_component" src={javascript_frameworks} alt="Javascript frameworks"></img>
    <p className="services_component">This is the best choise for websites that wants the best performance and useability.</p>
    </div>

    <h2>Other services</h2>
    <p>On top of our web development services, we also provide maintenance and web hosting for your website.</p>
    <div className="services_list">
    <h3>Support</h3>
    <img className="services_component" src={maintenance} alt="Maintenance"></img>
    <p className="services_component">You own all the rights to your webpage as soon as we have delivered it to you. Therefore it is also your responibility if anything breaks or goes wrong. However, for a small additional fee per month we offer full support of your webpage in case anything happens, thus eliminating any trouble that you may have to deal with.</p>
    </div>
    <div className="services_list">
    <h3>Support and hosting</h3>
    <img className="services_component" src={hosting} alt="Web hosting"></img>
    <p className="services_component">We aslo provide hosting for websites in case you don not like to do any maintenance on your web solution. If you would like to use your own hosting provider, we will make sure that your new website gets up and running on a host of your choice.</p>
    </div>

*/